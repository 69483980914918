<template>
    <v-container class="l-primary-font f20 fw600 d-flex flex-column mb-10 mt-3">
        <v-row no-gutters >
            <v-col cols="12" md="8">
                <span>
                    Other Course Offerings
                </span>
            </v-col>
            <v-spacer />
            <v-col cols="12" md="3" v-if="!$vuetify.breakpoint.mobile">
                <v-text-field outlined dense class="float-right" color="l-primary" solo flat placeholder="Search courses" v-model="search" hide-details prepend-inner-icon="mdi-magnify"/>
            </v-col>
        </v-row>
        <v-divider class="l-primary" width="50%" />
        <div v-if="all_courses.length === 0" class="fw600 f14 secondary--text my-3"><i>No Courses Available</i></div>

        <section v-if="all_courses.length>0" class="d-flex my-10" :class="[$vuetify.breakpoint.mobile ? 'flex-column justify-center' : 'flex-wrap']">
            <galleryCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-1" v-if="!$vuetify.breakpoint.mobile"/>
            <listCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-3" v-if="$vuetify.breakpoint.mobile"/>
        </section>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import galleryCard from "@/components/landing/cards/gallery-card.vue";
import listCard from "@/components/landing/cards/list-card.vue"

export default {
    data:() => ({
        search: ''
    }),
    components: {
        galleryCard,
        listCard
    },
    mounted() {
        this.getTenantCoursesAction({ is_free: false})
    },
    computed: {
        ...mapState({
            all_courses: (state) =>  [...state.paid_courses, ...state.free_courses],
        }),

        filtered() {
            if(this.search.length > 0) {
                return this.all_courses.filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))
            } else {
                return this.all_courses
            }
        }
    },
    methods: {
        ...mapActions(['getTenantCoursesAction'])
    }
}
</script>